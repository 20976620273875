import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import projImg7 from "../assets/img/project-img7.png";
import projImg8 from "../assets/img/project-img8.png";
import projImg9 from "../assets/img/project-img9.png";
import disImg1 from "../assets/img/diseno-img1.webp";
import disImg2 from "../assets/img/diseno-img2.jpg";
import disImg3 from "../assets/img/diseno-img3.webp";
import mantImg1 from "../assets/img/mant-img1.webp";
import mantImg2 from "../assets/img/mant-img2.webp";
import mantImg3 from "../assets/img/mant-img3.webp";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Comercial Michelena",
      description: "Actualización de tienda en línea y soluciones en servidor",
      imgUrl: projImg1,
      urlsite:"https://comercialmichelena.com",
    },
    {
      title: "Spartan Academy",
      description: "Venta de Cursos en línea y automatización de su plataforma de aprendizaje",
      imgUrl: projImg2,
      urlsite:"https://spartaninter.com/",
    },
    {
      title: "El palacio del colchón",
      description: "Rediseño de tienda en línea e implementación de asistente de compras virtual y pasarela de pagos",
      imgUrl: projImg3,
      urlsite:"https://elpalaciodelcolchon.com/",
    },
    {
      title: "Hotel de Mi Pueblo",
      description: "Diseño y Desarrollo de su sitio web e integración con su plataforma de reservas",
      imgUrl: projImg4,
      urlsite:"https://hoteldemipueblo.com/",
    },
    {
      title: "VAA Abogados",
      description: "Diseño y Desarrollo de su sitio web corporativo",
      imgUrl: projImg5,
      urlsite:"https://vaabogados.legal/",
    },
    {
      title: "Belén Ruales",
      description: "Desarrollo de su sitio web para mostrar su talento al mundo",
      imgUrl: projImg6,
      urlsite:"https://belenruales.com/",
    }, 
    {
      title: "CEPAM GYE",
      description: "Desarrollo de su sitio web informativo y de contactos",
      imgUrl: projImg7,
      urlsite:"https://cepamgye.org/",
    },
    {
      title: "Arco Iris Atacames",
      description: "Desarrollo de su propia plataforma de reservas de habitaciones",
      imgUrl: projImg8,
      urlsite:"https://arcoirisatacames.com/",
    },
    {
      title: "State of Nirvana",
      description: "Desarrollo de su propia plataforma de venta y reserva de productos y servicios",
      imgUrl: projImg9,
      urlsite:"https://stateofnirvana.vip/",
    }
    
  ];

  const diseno = [
    {
      title: "Mockups UX/UI",
      description: "Creamos un mockup para tu software",
      imgUrl: disImg1,
      urlsite:"https://wa.link/wcoe1x",
    },
    {
      title: "Edición de imágenes",
      description: "Te ayudamos con el diseño de tus productos para tu tienda en línea",
      imgUrl: disImg2,
      urlsite:"https://wa.link/wcoe1x",
    },
    {
      title: "Creación de Logos y Diseños",
      description: "Servicio personalizado le damos vida a tu idea",
      imgUrl: disImg3,
      urlsite:"https://wa.link/wcoe1x",
    }
  ];
  const mantenimiento = [
    {
      title: "Actualización de equipos",
      description: "Cambia tu viejo disco por un SSD",
      imgUrl: mantImg1,
      urlsite:"https://wa.link/wcoe1x",
    },
    {
      title: "Instalación de programas",
      description: "¿Necesitas una licencia de antivirus o una licencia de office? Te ayudamos",
      imgUrl: mantImg2,
      urlsite:"https://wa.link/wcoe1x",
    },
    {
      title: "Limpieza y Mantenimiento",
      description: "Servicio personalizado y a domicilio. Vamos hasta tu oficina u hogar",
      imgUrl: mantImg3,
      urlsite:"https://wa.link/wcoe1x",
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Proyectos</h2>
                <p>Te mostramos algunos de los caso de exito de nuestros clientes, facilitando su vida y ampliando sus ventas o su campo profesional.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tiendas en Línea y <br></br>Sitios Web</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Diseño<br></br>Gráfico</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Mantenimeinto<br></br> de Equipos</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          diseno.map((diseno, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...diseno}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          mantenimiento.map((mantenimiento, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...mantenimiento}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
