import mantenimiento from "../assets/img/Meter2.png";
import desarrollo from "../assets/img/Meter3.png";
import diseno from "../assets/img/Meter4.png";
import software from "../assets/img/Meter1.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Especialidades</h2>
                        <p>En IT Service EC hemos trabajado de la mano de pequeñas y medianas empresas. Hemos ayudado a profesionales a mostrar su talento.<br></br>Tambien hemos brindado soluciones a mircroempresas en implementaciones de Software y soluciones de Hardware</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={desarrollo} alt="Image" />
                                <h5>Desarrollo Web</h5>
                            </div>
                            <div className="item">
                                <img src={diseno} alt="Image" />
                                <h5>Diseño e Identidad de Marca</h5>
                            </div>
                            <div className="item">
                                <img src={mantenimiento} alt="Image" />
                                <h5>Mantenimiento y Reparación de Equipos</h5>
                            </div>
                            <div className="item">
                                <img src={software} alt="Image" />
                                <h5>Soluciones de Software</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
